import { Button, Card, CardAction, Modal, Select } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import './create-contract-modal.scss';
import { useCreateInsuranceProviderContract } from 'src/service/contract/CreateInsuranceProviderContract';
import { CreateInsuranceProviderContractRequest } from 'src/types/contract/request/CreateInsuranceProviderContractRequest';
import { createOptionsFromList } from 'src/util/OptionUtil';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { Option } from 'fgirot-k2-ui-components';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { isContractRequestValid } from 'src/util/ContractUtil';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import InfoMessage from 'src/components/Common/InfoMessage';

interface CreateContractModalProps {
  open: boolean;
  onClose: () => void;
  employerId: string;
  insuranceProviderContracts: InsuranceProviderContract[];
}

const CreateContractModal: FC<CreateContractModalProps> = ({
  open,
  onClose,
  employerId,
  insuranceProviderContracts,
}) => {
  const { t } = useTranslation();
  const createInsuranceProviderContract = useCreateInsuranceProviderContract();
  const [createInsuranceProviderContractRequest, setCreateInsuranceProviderContractRequest] =
    useState<CreateInsuranceProviderContractRequest>({
      insuranceProviderId: '',
      name: '',
      invoiceInterval: 0,
      agreementNumber: '',
      agreedProducts: [],
    });

  const handleOnClose = () => {
    onClose();
  };

  const handleCreateContract = () => {
    if (!isDuplicateContract()) {
      createInsuranceProviderContract(employerId, createInsuranceProviderContractRequest)
        .catch(() => {
          alert('Error when creating insurance provider contract');
        })
        .finally(() => {
          handleOnClose();
        });
    }
  };
  const handleAgreementNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCreateInsuranceProviderContractRequest((prev) => {
      return { ...prev, agreementNumber: event.target.value };
    });
  };

  const handleInsuranceProviderChange = (option: Option<string>) => {
    setCreateInsuranceProviderContractRequest((prev) => {
      return { ...prev, insuranceProviderId: option.value };
    });
  };

  const handleNameChange = (name: string) => {
    setCreateInsuranceProviderContractRequest((prev) => {
      return { ...prev, name };
    });
  };

  const isDuplicateContract = (): boolean => {
    return insuranceProviderContracts.some(
      (contract) =>
        contract.agreementNumber === createInsuranceProviderContractRequest.agreementNumber &&
        contract.insuranceProvider.id === createInsuranceProviderContractRequest.insuranceProviderId,
    );
  };

  return (
    <Modal open={open} centered overlayBackground onClose={onClose}>
      <Card
        className="create-contract-modal"
        title={t('agreementRegistrySettings:contract-modal.new-contract')}
        size="narrow"
      >
        <TextInput
          label={t('agreementRegistrySettings:contract-modal.contract-name')}
          type="text"
          validationKey="text64"
          variant="default"
          placeholder={t('agreementRegistrySettings:contract-modal.set-contract-name')}
          onChange={(e) => handleNameChange(e.target.value)}
          value={createInsuranceProviderContractRequest.name}
          maxLength={65}
          mandatory
          data-cy="contract-name__input"
        />
        <TextInput
          label={t('agreementRegistrySettings:agreement-number')}
          type="text"
          validationKey="text64"
          variant="default"
          placeholder={t('agreementRegistrySettings:contract-modal.set-agreement-number')}
          onChange={handleAgreementNumberChange}
          value={createInsuranceProviderContractRequest.agreementNumber}
          maxLength={65}
          mandatory
          data-cy="contract-agreement-number__input"
        />
        <QueryWrapper query={GET_INSURANCE_PROVIDERS} type="list" options={{ variables: { employerId } }}>
          {(insuranceProviders: InsuranceProvider[]) => {
            const insuranceProviderOptions = createOptionsFromList(insuranceProviders, 'id', 'legalName');
            return (
              <Select
                label={t('agreementRegistrySettings:insurance-provider')}
                mandatory
                small
                outlined
                className="create-contract-modal__dropdown"
                placeholder={t('agreementRegistrySettings:contract-modal.choose-insurance-provider')}
                options={insuranceProviderOptions}
                selected={insuranceProviderOptions.find(
                  (option) => option.value === createInsuranceProviderContractRequest.insuranceProviderId,
                )}
                onChange={handleInsuranceProviderChange}
                data-cy="contract-provider__dropdown"
              />
            );
          }}
        </QueryWrapper>
        {isDuplicateContract() && (
          <InfoMessage message={t('agreementRegistrySettings:contract-modal.duplicate-error')} />
        )}
        <CardAction>
          <Button
            label={t('common:cancel')}
            type="link"
            onClick={handleOnClose}
            data-cy="create-contract-modal__cancel-button"
          />
          <Button
            label={t('common:create')}
            disabled={!isContractRequestValid(createInsuranceProviderContractRequest) || isDuplicateContract()}
            onClick={handleCreateContract}
            data-cy="create-contract-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreateContractModal;
