import { Button, Icon, ListGroup, DrawerNavigation, Banner, Typography, Pill } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import EmployerWageTypeLabel from './EmployerWageTypeLabel';
import './wage-types-overview-drawer.scss';
import DraftChangedTooltip from 'src/components/Common/DraftChangedTooltip';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';
import When from 'src/components/Common/When';

interface WageTypesOverviewDrawerProps {
  onClose: () => void;
  onBackClick: () => void;
  onSelectWageType: (wageId: string) => void;
  onSelectPayType: (payTypeNumber: string) => void;
  onSelectWage: (wageId: string) => void;
  displayCreateDraftWageDrawer: () => void;
  aggregatedEmployeeCards: AggregatedEmployeeCard[];
  employerId: string;
  selectedPeriod: string;
  isDraft: boolean;
}
export const WageTypesOverviewDrawer: FC<WageTypesOverviewDrawerProps> = ({
  onClose,
  onBackClick,
  onSelectWageType,
  onSelectPayType,
  onSelectWage,
  aggregatedEmployeeCards,
  employerId,
  selectedPeriod,
  isDraft,
  displayCreateDraftWageDrawer,
}) => {
  const { t } = useTranslation();

  const formattedPeriod = getFormattedDateStringFromPeriod(selectedPeriod);
  const selectedCard = aggregatedEmployeeCards.find((s) => s.period == selectedPeriod);
  const employerWageTypes = useEmployerWageTypes(employerId);

  const grossSalaryWage = selectedCard?.aggregatedWageTypeWages.find((w) =>
    w.wages.every((w) => w.wageType === 'GROSS_SALARY'),
  );

  const filteredWageTypeWages = selectedCard?.aggregatedWageTypeWages.filter((w) =>
    w.wages.every((w) => w.wageType !== 'GROSS_SALARY'),
  );

  const handleDisplayGrossSalaryWage = () => {
    onSelectWageType(grossSalaryWage.employerWageTypeId);
    onSelectWage(grossSalaryWage.wages[0].id);
  };

  return (
    <DrawerBase
      title={t('wages:wage-types')}
      subTitle={<Pill type="success" label={formattedPeriod} />}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <div className="wage-types-overview-drawer">
        {isDraft && <EmployeeInDraftBanner />}
        {grossSalaryWage && (
          <ListGroup variant="drawer-navigation">
            <DrawerNavigation
              label={
                <DraftChangedTooltip
                  label={t('employees:employee-draft-pay-type-changed')}
                  hasChangesInDraft={grossSalaryWage.wages.some((w) => w.isDraftModified)}
                >
                  <Typography bold variant="body2">
                    {t('wageTypes:GROSS_SALARY')}
                  </Typography>
                  <Pill size="small" type="heads-up" label={selectedCard.grossSalaryFromDate} />
                </DraftChangedTooltip>
              }
              value={formatSweAmountTextWithSuffixNoDecimal(grossSalaryWage.amount)}
              onClick={handleDisplayGrossSalaryWage}
            />
          </ListGroup>
        )}
        <When condition={!!filteredWageTypeWages}>
          <ListGroup variant="drawer-navigation">
            {filteredWageTypeWages.map((aggregatedWage) => {
              const hasWagesChangedInDraft = aggregatedWage.wages.some((w) => w.isDraftModified);
              return (
                <DrawerNavigation
                  label={
                    <EmployerWageTypeLabel
                      employerWageTypes={employerWageTypes}
                      employerWageTypeId={aggregatedWage.employerWageTypeId}
                      hasWagesChangedInDraft={hasWagesChangedInDraft}
                    />
                  }
                  value={formatSweAmountTextWithSuffixNoDecimal(aggregatedWage.amount)}
                  onClick={() => onSelectWageType(aggregatedWage.employerWageTypeId)}
                  key={aggregatedWage.employerWageTypeId}
                />
              );
            })}
          </ListGroup>
        </When>
        <When condition={!!selectedCard?.aggregatedPayTypeWages}>
          <ListGroup variant="drawer-navigation">
            {selectedCard.aggregatedPayTypeWages.map((aggregatedWage) => {
              const hasWagesChangedInDraft = aggregatedWage.wages.some((w) => w.isDraftModified);
              return (
                <DrawerNavigation
                  label={
                    <DraftChangedTooltip
                      hasChangesInDraft={hasWagesChangedInDraft}
                      label={t('employees:employee-draft-wage-type-changed')}
                    >
                      <Typography variant="body2" bold>
                        {`${aggregatedWage.payTypeNumber} - ${aggregatedWage.payTypeName}`}
                      </Typography>
                    </DraftChangedTooltip>
                  }
                  value={formatSweAmountTextWithSuffixNoDecimal(aggregatedWage.payedAmount)}
                  onClick={() => onSelectPayType(aggregatedWage.payTypeNumber)}
                  key={aggregatedWage.payTypeNumber}
                />
              );
            })}
          </ListGroup>
        </When>
        <When condition={!filteredWageTypeWages && !selectedCard?.aggregatedPayTypeWages}>
          <Banner type="warning" message={t('wages:error-no-employee-card')} />
        </When>
        {isDraft && (
          <ListGroup variant="drawer-navigation">
            <DrawerNavigation label={t('wages:add-wage')} onClick={displayCreateDraftWageDrawer} />
          </ListGroup>
        )}
      </div>
    </DrawerBase>
  );
};
export default WageTypesOverviewDrawer;
