import React, { useState } from 'react';
import InsuranceEventsTableHead from './InsuranceEventsTableHead';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import TableBase from 'src/components/Common/TableBase';
import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import {
  sortAlphabeticalAsc,
  sortAlphabeticalDesc,
  sortDateAsc,
  sortDateDesc,
  sortNumericalAsc,
  sortNumericalDesc,
} from 'src/util/SortUtil';
import InsuranceEventTableRow from './InsuranceEventTableRow';
import { InsuranceEventsSortField } from './InsuranceEventsTableHead/InsuranceEventsSortField';
import { useTranslation } from 'react-i18next';

interface InsuranceEventsTableProps {
  insuranceEvents: InsuranceEvent[];
  onDrawerOpen: (insuranceEventId: string) => void;
}

const InsuranceEventsTable = ({ insuranceEvents, onDrawerOpen }: InsuranceEventsTableProps) => {
  const [sortField, setSortField] = useState<InsuranceEventsSortField>('insuranceProvider');
  const [sortDirection, setSortDirection] = useState<SortDirection>('UP');

  const { t } = useTranslation();

  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));
  const handleSortFieldChange = (sortField: InsuranceEventsSortField) => setSortField(sortField);
  const sortDate = sortDirection === 'UP' ? sortDateDesc : sortDateAsc;

  const sortFunction = (a: InsuranceEvent, b: InsuranceEvent) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerial = sortDirection === 'UP' ? sortNumericalAsc : sortNumericalDesc;

    switch (sortField) {
      case 'insuranceProvider':
        return sortAlphabetical(a.insuranceProvider?.legalName, b.insuranceProvider?.legalName);
      case 'reportingType':
        return sortAlphabetical(t(`reportingTypes:${a.reportingType}`), t(`reportingTypes:${b.reportingType}`));
      case 'insuranceEventType':
        return sortAlphabetical(
          t(`insuranceEventTypes:${a.insuranceEventType}`),
          t(`insuranceEventTypes:${b.insuranceEventType}`),
        );
      case 'benefitName':
        return sortAlphabetical(a.policyBenefitName?.name, b.policyBenefitName?.name);
      case 'wageFilePeriod':
        return sortDate(a.wageFilePeriod, b.wageFilePeriod);
      case 'eventFromDate':
        return sortDate(a.eventFromDate, b.eventFromDate);
      case 'agreedProduct':
        return sortAlphabetical(
          a.employeeInsurance?.insurance?.agreedProduct?.name,
          b.employeeInsurance?.insurance?.agreedProduct?.name,
        );
      case 'certifyInsuranceProductCondition':
        return sortAlphabetical(
          t(`reporting:insurance-events.certify-insurance-product-condition.${a.certifyInsuranceProductCondition}`),
          t(`reporting:insurance-events.certify-insurance-product-condition.${b.certifyInsuranceProductCondition}`),
        );
      case 'eventValues':
        return sortNumerial(a.eventValues[0]?.currentValue ?? 0, b.eventValues[0]?.currentValue ?? 0);
      case 'status':
        return sortAlphabetical(t(`insuranceEventStatus:${a.status}`), t(`insuranceEventStatus:${b.status}`));
      default:
        return undefined;
    }
  };

  const sortedInsuranceEvents = [...insuranceEvents].sort(sortFunction);

  return (
    <TableBase>
      <TableHead>
        <InsuranceEventsTableHead
          sortField={sortField}
          sortDirection={sortDirection}
          onSortFieldChange={handleSortFieldChange}
          onSortDirectionChange={handleSortDirectionChange}
        />
      </TableHead>
      <TableBody>
        {sortedInsuranceEvents.map((insuranceEvent) => (
          <InsuranceEventTableRow
            key={insuranceEvent.insuranceEventId}
            insuranceEvent={insuranceEvent}
            onDrawerOpen={onDrawerOpen}
          />
        ))}
      </TableBody>
    </TableBase>
  );
};

export default InsuranceEventsTable;
