import { gql } from '@apollo/client';

export const INSURANCE_EVENT_UPDATED = gql`
  subscription InsuranceEventUpdated($insuranceEventIds: [UUID]!) {
    insuranceEventUpdated(insuranceEventIds: $insuranceEventIds) {
      insuranceEventId
      benefitType
      policyBenefitName {
        id
        name
      }
      reportingType
      insuranceEventType
      status
      wageFilePeriod
      eventFromDate
      eventValues {
        id
        eventValueType
        currentValue
        previousValue
      }
      certifyInsuranceProductCondition
    }
  }
`;
