import { t } from 'i18next';
import React from 'react';
import { useParams } from 'react-router-dom';
import AgreementRegistryOverview from 'src/components/Account/AgreementRegistrySettings/AgreementRegistryOverview/AgreementRegistryOverview';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import PageBase from 'src/components/PageBase';
import { GET_EMPLOYER } from 'src/graphql/schema/query/employer/GetEmployer';
import { GET_INSURANCE_PROVIDER_CONTRACTS } from 'src/graphql/schema/query/contract/GetInsuranceProviderContracts';
import { useNavigateWithFallback } from 'src/service/routes/LocationHooks';
import { Employer } from 'src/types/employer/Employer';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';

const AgreementRegistrySettings = () => {
  const { customerName, employerId } = useParams();

  const handleBackClick = useNavigateWithFallback(-1, '/');

  return (
    <QueryWrapper query={GET_EMPLOYER} options={{ variables: { employerId } }}>
      {(employer: Employer) => {
        const breadCrumbs = [
          {
            name: customerName,
            steps: 2,
          },
          {
            name: employer?.legalName,
            steps: 1,
          },
        ];
        return (
          <PageBase
            title={t('agreementRegistrySettings:agreement-registry')}
            handleBackButton={handleBackClick}
            breadCrumbs={breadCrumbs}
          >
            <QueryWrapper query={GET_INSURANCE_PROVIDER_CONTRACTS} type="list" options={{ variables: { employerId } }}>
              {(insuranceProviderContracts: InsuranceProviderContract[]) => (
                <AgreementRegistryOverview insuranceProviderContracts={insuranceProviderContracts} />
              )}
            </QueryWrapper>
          </PageBase>
        );
      }}
    </QueryWrapper>
  );
};

export default AgreementRegistrySettings;
