import { gql } from '@apollo/client';

export const SEARCH_INSURANCE_EVENTS = gql`
  query SearchInsuranceEvents($searchInsuranceEventsRequest: SearchInsuranceEventsRequest!) {
    searchInsuranceEvents(searchInsuranceEventsRequest: $searchInsuranceEventsRequest) {
      paging {
        totalNumberOfElements
        hasNext
      }
      persons {
        personNumber
        employees {
          id
          firstName
          lastName
          personNumber
          employerId
          employmentNumber
          hasDraft
        }
        insuranceEvents {
          insuranceEventId
          employeeId
          benefitType
          policyBenefitName {
            id
            name
          }
          reportingType
          insuranceEventType
          status
          wageFilePeriod
          insuranceProvider {
            id
            legalName
          }
          employeeCard {
            period
            employmentGroupId
            employmentCategory
            evaluationPolicyEntityId
          }
          eventFromDate
          certifyInsuranceProductCondition
          eventValues {
            id
            currentValue
            previousValue
            eventValueType
          }
          employeeInsurance {
            id
          }
        }
      }
    }
  }
`;
