import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceProviderContract } from 'src/types/contract/InsuranceProviderContract';
import { Option } from 'fgirot-k2-ui-components';
import { createOptions } from 'src/util/OptionUtil';
import { unique } from 'src/util/ArrayUtil';
import FilterComponent from 'src/components/Common/FilterComponent';
import ContractListItem from './ContractListItem/ContractListItem';
import AgreementRegistryDrawer from '../AgreementRegistryDrawer';
import './agreement-registry-overview.scss';
import { ContractAgreedProduct } from 'src/types/contract/ContractAgreedProduct';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import CreateContractModal from '../CreateContractModal';
import { useParams } from 'react-router-dom';

interface AgreementRegistryOverviewProps {
  insuranceProviderContracts: InsuranceProviderContract[];
}

const AgreementRegistryOverview = ({ insuranceProviderContracts }: AgreementRegistryOverviewProps) => {
  const { t } = useTranslation();
  const [selectedContractId, setSelectedContractId] = useState<string>();
  const [insuranceProviderFilter, setInsuranceProviderFilter] = useState<Option<string>[]>([]);
  const [createContractModalOpen, setCreateContractModalOpen] = useState(false);
  const { employerId } = useParams();

  const handleDrawerOpen = (id: string) => {
    setSelectedContractId(id);
  };

  const handleDrawerClose = () => {
    setSelectedContractId(undefined);
  };

  const handleOpenCreateContractModal = () => {
    setCreateContractModalOpen(true);
  };

  const handleCloseCreateContractModal = () => {
    setCreateContractModalOpen(false);
  };

  const insuranceProviderOptions = createOptions(
    unique(
      insuranceProviderContracts.flatMap((contract) =>
        contract.agreedProducts.map((product) => product.insuranceProduct?.insuranceProvider?.legalName),
      ),
    ),
  );

  const selectProps = [
    {
      placeholder: t('agreementRegistrySettings:insurance-provider'),
      options: insuranceProviderOptions,
      selected: insuranceProviderFilter,
      onChange: (options: Option<string>[]) => setInsuranceProviderFilter([...options]),
      'data-cy': 'agreement-registry-overview__insurance-provider-filter',
    },
  ];

  const filterInsuranceProvider = (agreedProducts: ContractAgreedProduct[]): boolean => {
    return insuranceProviderFilter.length > 0
      ? agreedProducts.some((product) =>
          insuranceProviderFilter.some(
            (providerOption) => providerOption.value === product.insuranceProduct?.insuranceProvider?.legalName,
          ),
        )
      : true;
  };

  const filteredInsuranceProviderContracts = insuranceProviderContracts.filter((contract) =>
    filterInsuranceProvider(contract.agreedProducts),
  );

  return (
    <section className="agreement-registry-overview">
      <FilterComponent selectProps={selectProps} />
      <ul className="agreement-registry-overview__list">
        {filteredInsuranceProviderContracts.map((contract) => (
          <ContractListItem key={contract.id} contract={contract} handleDrawerOpen={handleDrawerOpen} />
        ))}
      </ul>
      {selectedContractId !== undefined && (
        <AgreementRegistryDrawer
          open={selectedContractId !== undefined}
          onClose={handleDrawerClose}
          contractId={selectedContractId}
        />
      )}

      {createContractModalOpen && (
        <CreateContractModal
          open={createContractModalOpen}
          onClose={handleCloseCreateContractModal}
          employerId={employerId}
          insuranceProviderContracts={insuranceProviderContracts}
        />
      )}
      <FloatingAddButton
        data-cy="add-contract-button"
        label={t('agreementRegistrySettings:add-contract')}
        onClick={handleOpenCreateContractModal}
      />
    </section>
  );
};

export default AgreementRegistryOverview;
