import React, { useState } from 'react';
import { Button, Card, CardAction, Icon, Modal, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/types/invoice/Invoice';

interface DeletePaymentTransactionModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  invoice: Invoice;
}

const DeletePaymentTransactionModal: React.FC<DeletePaymentTransactionModalProps> = ({
  open,
  onClose,
  onConfirm,
  invoice,
}) => {
  const { t } = useTranslation();
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);

  const handleDelete = async () => {
    setDeleteButtonDisabled(true);
    await onConfirm();
    setDeleteButtonDisabled(false);
    onClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onClose} data-cy="delete-payment-transaction-modal">
      <Card
        icon={<Icon type="Alert" stroke="#fda522" />}
        title={t('account:payment-transactions.delete-modal.title')}
        defaultClose
        onClose={onClose}
      >
        <div>
          <Typography component="span">{`${t('account:payment-transactions.delete-modal.delete-text')} `}</Typography>
          <Typography component="span">
            <strong>{invoice.invoiceNumber}</strong>
          </Typography>
        </div>
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={onClose}
            data-cy="delete-payment-transaction-modal__cancel-button"
          />
          <Button
            disabled={deleteButtonDisabled}
            icon={<Icon type="Trash" />}
            label={t('common:delete')}
            onClick={handleDelete}
            data-cy="delete-payment-transaction-modal__delete-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default DeletePaymentTransactionModal;
