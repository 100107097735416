import { gql } from '@apollo/client';

export const GET_LATEST_EMPLOYEE_CARD = gql`
  query GetLatestEmployeeCard($employeeId: UUID!, $period: String, $allowDraft: Boolean) {
    latestEmployeeCard(employeeId: $employeeId, period: $period, allowDraft: $allowDraft) {
      id
      employeeId
      employmentGroupId
      employmentCategory
      evaluationPolicyEntityId
      remunerationType
      sickDays
      workingPercentage
      partTimePension
      entitledVacationDays
      hasSickAndActivityCompensation
      grossSalaryFromDate
      employmentType
      period
      isDraft
      aggregatedWageTypeWages {
        amount
        employerWageTypeId
        wages {
          id
          wageType
          employerWageTypeId
          payTypeName
          payTypeNumber
          amount
          payedAmount
          currency
          fromDate
          toDate
          quantityValue
          quantityUnit
          useQuantity
          inUse
          isDraftModified
        }
      }
      aggregatedPayTypeWages {
        payTypeNumber
        payTypeName
        payedAmount
        wages {
          id
          wageType
          payTypeName
          payTypeNumber
          kubTypes
          amount
          payedAmount
          fromDate
          toDate
          inUse
          isDraftModified
        }
      }
      accountingComponents {
        id
        accountingComponentValue {
          id
          name
          accountingComponentType {
            id
            name
          }
        }
        share
        subAccountingComponents {
          id
          accountingComponentValue {
            id
            name
            accountingComponentType {
              id
              name
            }
          }
          share
        }
      }
    }
  }
`;
