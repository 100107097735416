import { Button } from 'fgirot-k2-ui-components';
import React, { useEffect } from 'react';
import TextArea from 'src/components/Common/TextArea';
import { InsuranceEvent, InsuranceEventHandlingType } from 'src/types/reporting/InsuranceEvent';
import './handle-insurance-event-inputs.scss';
import { SMALL_TEXTAREA_MAX_CHARACTERS } from 'src/validation/DataTypeConstant';
import { useTranslation } from 'react-i18next';
import { useHandleInsuranceEvent } from 'src/service/reporting/HandleInsuranceEvent';
import { HandleInsuranceEventRequest } from 'src/types/reporting/HandleInsuranceEventRequest';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';

interface HandleInsuranceEventInputsProps {
  insuranceEvent: InsuranceEvent;
  eventReport?: InsuranceEventReportEmployeeResponse;
  handlingType: InsuranceEventHandlingType;
  employerId: string;
  onBackClick: () => void;
  onClose?: () => void;
}

const HandleInsuranceEventInputs = ({
  insuranceEvent,
  eventReport,
  handlingType,
  employerId,
  onBackClick,
  onClose,
}: HandleInsuranceEventInputsProps) => {
  const handleInsuranceEvent = useHandleInsuranceEvent();
  const [comment, setComment] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    const request: HandleInsuranceEventRequest = {
      insuranceEventId: insuranceEvent.insuranceEventId,
      handlingType,
      comment,
    };
    setLoading(true);
    try {
      await handleInsuranceEvent(employerId, request);
    } finally {
      setLoading(false);
    }
    onBackClick();
  };

  useEffect(() => {
    if (!eventReport && !insuranceEvent?.employeeId) {
      onClose && onClose();
    }
  }, [eventReport, insuranceEvent?.employeeId]);

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  return (
    <div className="handle-insurance-event-inputs">
      <TextArea
        small
        value={comment}
        onChange={handleCommentChange}
        validationKey="text255"
        characterLimit={SMALL_TEXTAREA_MAX_CHARACTERS}
        placeholder={t('reporting:insurance-events.add-comment')}
      />
      <Button
        type="primary"
        label={t('reporting:insurance-events.handle.title')}
        onClick={handleSubmit}
        disabled={loading}
        loading={loading}
      />
    </div>
  );
};

export default HandleInsuranceEventInputs;
