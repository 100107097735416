import { Banner, Pill, Typography } from 'fgirot-k2-ui-components';
import { ListGroup } from 'fgirot-k2-ui-components';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import './insurance-event-about-tab.scss';
import { useTranslation } from 'react-i18next';
import { usePeriodText } from 'src/util/date/PeriodUtil';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { GET_EMPLOYEE_INSURANCE } from 'src/graphql/schema/query/employee/GetEmployeeInsurance';
import { EmployeeInsurance } from 'src/types/insurance/Insurance';
import ScrollBase from 'src/components/Common/ScrollBase/ScrollBase';
import ListProperty from 'src/components/Common/ListProperty/ListProperty';
import { stringOrBlank } from 'src/util/StringUtil';
import { getCorrectEventValue } from 'src/util/InsuranceEventUtil';

interface InsuranceEventAboutTabProps {
  insuranceEvent: InsuranceEvent;
}

const InsuranceEventAboutTab = ({ insuranceEvent }: InsuranceEventAboutTabProps) => {
  const { t } = useTranslation();

  return (
    <ScrollBase maxHeightPercentage={60} className="insurance-event-about-tab">
      <ListGroup variant="inline-edit">
        <ListProperty
          data-testid="insurance-event-about-tab__status"
          label={t('reporting:insurance-events.status')}
          value={t(`insuranceEventStatus:${insuranceEvent.status}`)}
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        <ListProperty
          data-testid="insurance-event-about-tab__employee-card-period"
          label={t('reporting:insurance-events.basis')}
          value={usePeriodText(insuranceEvent.employeeCard?.period)}
        />
        <ListProperty
          data-testid="insurance-event-about-tab__event-from-date"
          label={t('reporting:insurance-events.event-from-date')}
          value={stringOrBlank(insuranceEvent.eventFromDate)}
        />
        <ListProperty
          data-testid="insurance-event-about-tab__insurance-event-type"
          label={t('reporting:insurance-events.insurance-event-type')}
          value={t(`insuranceEventTypes:${insuranceEvent.insuranceEventType}`)}
        />
      </ListGroup>
      <ListGroup variant="inline-edit">
        {insuranceEvent.eventValues.map((eventValue) => (
          <ListProperty
            key={eventValue.eventValueType}
            label={t('reporting:insurance-events.value')}
            value={
              <div className="insurance-event-about-tab__values">
                <Pill type="neutral" size="small" label={eventValue.eventValueType} />
                <Typography variant="caption">
                  {formatSweAmountTextWithSuffixNoDecimal(getCorrectEventValue(eventValue))}
                </Typography>
              </div>
            }
          />
        ))}
      </ListGroup>
      {insuranceEvent.employeeInsurance?.id ? (
        <QueryWrapper
          query={GET_EMPLOYEE_INSURANCE}
          options={{
            variables: { employeeInsuranceId: insuranceEvent.employeeInsurance?.id },
            skip: !insuranceEvent.employeeInsurance,
          }}
        >
          {(employeeInsurance: EmployeeInsurance) => (
            <>
              <Typography variant="subtitle" bold className="insurance-event-about-tab__sub-title">
                {t('reporting:insurance-events.insurance')}
              </Typography>
              <ListGroup variant="inline-edit">
                {insuranceEvent.insuranceProvider && (
                  <ListProperty
                    data-testid="insurance-event-about-tab__recipient"
                    label={t('reporting:insurance-events.recipient')}
                    value={insuranceEvent.insuranceProvider.legalName}
                  />
                )}
                <ListProperty
                  data-testid="insurance-event-about-tab__benefit"
                  label={t('reporting:insurance-events.benefit')}
                  value={insuranceEvent.policyBenefitName?.name}
                />
                <ListProperty
                  data-testid="insurance-event-about-tab__benefit-type"
                  label={t('reporting:insurance-events.benefit-type')}
                  value={<Pill label={t(`benefitTypes:abbreviations.${employeeInsurance.insurance.benefitType}`)} />}
                />
                <ListProperty
                  data-testid="insurance-event-about-tab__agreement-number"
                  label={t('reporting:insurance-events.agreement-number')}
                  value={employeeInsurance.insurance.agreementNumber}
                />
                {employeeInsurance.insurance.insuranceNumber && (
                  <ListProperty
                    data-testid="insurance-event-about-tab__insurance-number"
                    label={t('reporting:insurance-events.insurance-number')}
                    value={employeeInsurance.insurance.insuranceNumber}
                  />
                )}
                {employeeInsurance.insurance.agreedProduct.id && (
                  <ListProperty
                    data-testid="insurance-event-about-tab__agreed-product"
                    label={t('reporting:insurance-events.agreed-product')}
                    value={employeeInsurance.insurance.agreedProduct.name}
                  />
                )}
              </ListGroup>
            </>
          )}
        </QueryWrapper>
      ) : (
        <Banner type="announcement" message={t('reporting:insurance-events.no-insurance')} />
      )}
    </ScrollBase>
  );
};

export default InsuranceEventAboutTab;
