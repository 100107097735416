import { InlineEdit, ListGroup, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsuranceEvent, InsuranceEventHandlingType } from 'src/types/reporting/InsuranceEvent';
import { Option } from 'fgirot-k2-ui-components';
import { useState } from 'react';
import {
  insuranceEventIsDismissable,
  insuranceEventIsPausable,
  insuranceEventIsPossibleToMarkAsExternallyReported,
} from 'src/util/InsuranceEventUtil';
import HandleInsuranceEventInputs from '../../../HandleInsuranceEventInputs';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';

interface InsuranceEventHandleTabProps {
  insuranceEvent: InsuranceEvent;
  eventReport?: InsuranceEventReportEmployeeResponse;
  employerId: string;
  onBackClick: () => void;
  onClose?: () => void;
}

const InsuranceEventHandleTab = ({
  insuranceEvent,
  eventReport,
  employerId,
  onBackClick,
  onClose,
}: InsuranceEventHandleTabProps) => {
  const [selectedType, setSelectedType] = useState<InsuranceEventHandlingType>();
  const { t } = useTranslation();
  const canBePaused = insuranceEventIsPausable(insuranceEvent);
  const canBeDismissed = insuranceEventIsDismissable(insuranceEvent);
  const canBeMarkedAsExternallyReported = insuranceEventIsPossibleToMarkAsExternallyReported(insuranceEvent);

  const handleOptions: Option<InsuranceEventHandlingType>[] = [
    {
      label: t('common:select'),
      value: null,
    },
  ];

  canBePaused &&
    handleOptions.push({
      label: t('insuranceEventHandlingTypes:PAUSE'),
      value: 'PAUSE',
    });

  canBeDismissed &&
    handleOptions.push({
      label: t('insuranceEventHandlingTypes:DISMISS'),
      value: 'DISMISS',
    });

  canBeMarkedAsExternallyReported &&
    handleOptions.push({
      label: t('insuranceEventHandlingTypes:EXTERNALLY_REPORTED'),
      value: 'EXTERNALLY_REPORTED',
    });

  const onTypeChange = (option: Option<InsuranceEventHandlingType>) => {
    setSelectedType(option.value);
  };

  return (
    <div className="insurance-event-message-tab">
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('employeeInsuranceEvents:drawer.handle-tab.pick-type-label')} bold>
          <Select
            options={handleOptions}
            selected={handleOptions.find((opt) => opt.value === selectedType)}
            onChange={onTypeChange}
            placeholder={t('employeeInsuranceEvents:drawer.handle-tab.pick-type-label')}
            alphabetical={false}
            disabled={handleOptions.length === 1}
            small
          />
        </InlineEdit>
      </ListGroup>
      {selectedType && (
        <HandleInsuranceEventInputs
          insuranceEvent={insuranceEvent}
          eventReport={eventReport}
          employerId={employerId}
          onBackClick={onBackClick}
          handlingType={selectedType}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default InsuranceEventHandleTab;
