import React, { useEffect, useState } from 'react';
import LoadingPage from 'src/components/Common/LoadingPage';
import { Employer } from 'src/types/employer/Employer';
import { InsuranceEventsPage } from 'src/types/reporting/InsuranceEventsPage';
import InsuranceEventsSearchResult from './InsuranceEventsSearchResult';
import './insurance-events-overview.scss';
import { useQuery } from '@apollo/client';
import { SEARCH_INSURANCE_EVENTS } from 'src/graphql/schema/query/insurance/SearchInsuranceEvents';
import { SearchEventsFilters, SearchEventsRequest } from 'src/types/reporting/SearchEventsRequest';
import { getInitialSearchEventsRequest } from 'src/components/Reporting/ReportingEvents/utils/eventUtils';
import FilterModalButton from 'src/components/Common/FilterModalButton';
import EventsFilterDetails from 'src/components/Common/EventsFilterDetails';
import EventsFilterModal from 'src/components/Common/EventsFilterModal';
import InsuranceEventsResultSummary from 'src/components/Report/Verify/InsuranceEvents/InsuranceEventsResultSummary';
import { Pagination } from 'fgirot-k2-ui-components';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import When from 'src/components/Common/When';
import { createPageSizeOptions } from 'src/util/OptionUtil';
import { useTranslation } from 'react-i18next';

interface InsuranceEventsOverviewProps {
  reportingPeriod: string;
  employers: Employer[];
  searchInput: string;
  className?: string;
}

const InsuranceEventsOverview = ({
  reportingPeriod,
  employers,
  searchInput,
  className,
}: InsuranceEventsOverviewProps) => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchRequest, setSearchRequest] = useState<SearchEventsRequest>(
    getInitialSearchEventsRequest(reportingPeriod, searchInput),
  );
  const handlePageChange = (page: number) => setPageNumber(page - 1);
  const { t } = useTranslation();

  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const { data, loading, startPolling } = useQuery<{
    searchInsuranceEvents: InsuranceEventsPage;
  }>(SEARCH_INSURANCE_EVENTS, {
    variables: {
      searchInsuranceEventsRequest: { ...searchRequest, pageNumber, pageSize, wageFilePeriod: reportingPeriod },
    },
  });

  const openFilterModal = () => setFilterModalOpen(true);
  const closeFilterModal = () => setFilterModalOpen(false);

  const applyFilters = (filters: SearchEventsFilters) => {
    setSearchRequest({
      ...searchRequest,
      filters,
    });
    setPageNumber(0);
  };

  useEffect(() => {
    startPolling(10000);
  }, []);

  useEffect(() => {
    if (searchInput !== searchRequest.searchInput) {
      setSearchRequest({
        ...searchRequest,
        searchInput,
      });
      setPageNumber(0);
    }
  }, [searchInput]);

  const totalPages = getTotalPages(data?.searchInsuranceEvents?.paging.totalNumberOfElements, pageSize);

  return (
    <div className="insurance-events-overview">
      <EventsFilterDetails searchRequest={searchRequest} applyFilters={applyFilters} />
      <InsuranceEventsResultSummary
        reportingPeriod={reportingPeriod}
        insuranceEventsPage={data?.searchInsuranceEvents}
      />
      <When condition={loading}>
        <LoadingPage />
      </When>
      <When condition={data?.searchInsuranceEvents?.persons.length > 0}>
        <ul>
          {data?.searchInsuranceEvents?.persons.map((person) => (
            <InsuranceEventsSearchResult key={person.personNumber} person={person} employers={employers} />
          ))}
        </ul>
      </When>
      <Pagination
        currentPage={pageNumber + 1}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        pageSize={pageSize}
        pageSizeLabel={t('common:results-per-page')}
        pageSizeOptions={pageSizeOptions}
        onPageSizeChange={setPageSize}
        pageSizePosition="left"
      />
      <When condition={filterModalOpen}>
        <EventsFilterModal
          open={filterModalOpen}
          onClose={closeFilterModal}
          filters={searchRequest.filters}
          period={reportingPeriod}
          employers={employers}
          applyFilters={applyFilters}
          isInsuranceEvents
        />
      </When>
      <FilterModalButton className={className} onClick={openFilterModal} />
    </div>
  );
};

export default InsuranceEventsOverview;
