import { Button, Card, CardAction, Icon, Modal, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { SearchAccountingItemsRequestFilters } from 'src/types/ledgerStorage/SearchAccountingItemsRequest';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import { Month } from 'src/types/Month';
import {
  convertMonthEnumToStringWithZeroes,
  convertMonthNumberStringToEnum,
  useMonthOptions,
} from 'src/util/date/Months';
import { useYearOptionsWithNextYear } from 'src/util/date/Years';
import InfoMessage from 'src/components/Common/InfoMessage';
import './accounting-items-filters-modal.scss';
import { getPeriodMonthString, getPeriodYear } from 'src/util/date/PeriodUtil';
import { unique } from 'src/util/ArrayUtil';
import { useEmployersInCustomer } from 'src/service/employer/GetEmployersInCustomer';

interface AccountingItemsFiltersModalProps {
  open: boolean;
  filters: SearchAccountingItemsRequestFilters;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchAccountingItemsRequestFilters) => void;
  customerId: string;
}

const AccountingItemsFiltersModal: FC<AccountingItemsFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
  customerId,
}) => {
  const { t } = useTranslation();
  const employers = useEmployersInCustomer(customerId, 'cache-first');
  const [fromPeriodYear, setFromPeriodYear] = useState<number>(
    getPeriodYear(filters.accountingPeriodFilter?.fromPeriod),
  );
  const [fromPeriodMonth, setFromPeriodMonth] = useState<string>(
    getPeriodMonthString(filters.accountingPeriodFilter?.fromPeriod),
  );
  const [toPeriodYear, setToPeriodYear] = useState<number>(getPeriodYear(filters.accountingPeriodFilter?.toPeriod));
  const [toPeriodMonth, setToPeriodMonth] = useState<string>(
    getPeriodMonthString(filters.accountingPeriodFilter?.toPeriod),
  );
  const [selectedInsuranceProviderIds, setSelectedInsuranceProviderIds] = useState<string[]>(
    filters.insuranceProviderIdsFilter || [],
  );
  const [selectedEmployerId, setSelctedEmployerId] = useState<string>(filters.employerId);

  const yearsOptions = useYearOptionsWithNextYear(true);
  const monthOptions = useMonthOptions(true);
  
  const handleEmployerSelectChange = (option: Option<string>) => {
    setSelctedEmployerId(option.value);
  };
  const handleInsuranceProvidersFilterChange = (options: Option<string>[]) => {
    setSelectedInsuranceProviderIds(options.map((option) => option.value));
  };

  const onApply = () => {
    const newFilters: SearchAccountingItemsRequestFilters = {
      ...filters,
      accountingPeriodFilter: {
        fromPeriod: fromPeriodYear && fromPeriodMonth ? `${fromPeriodYear}${fromPeriodMonth}` : null,
        toPeriod: toPeriodYear && toPeriodMonth ? `${toPeriodYear}${toPeriodMonth}` : null,
      },
      employerId: selectedEmployerId,
      insuranceProviderIdsFilter: selectedInsuranceProviderIds.length > 0 ? selectedInsuranceProviderIds : null,
    };
    onApplyFilters(newFilters);
    onClose();
  };

  useEffect(() => {
    fromPeriodYear === null && fromPeriodMonth !== null && setFromPeriodMonth(null);
    toPeriodYear === null && toPeriodMonth !== null && setToPeriodMonth(null);
  }, [fromPeriodYear, toPeriodYear]);

  const useButtonDisabled: boolean =
    (fromPeriodYear !== null && fromPeriodMonth === null) || (toPeriodYear !== null && toPeriodMonth === null);

  const employerOptions =
    employers.status !== 'loaded'
      ? []
      : employers.payload.map(
          (employer) =>
            ({
              label: employer.legalName,
              value: employer.id,
            } as Option<string>),
        );

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="accounting-items-filters-modal"
        title={t('economy:accounting-items-filters-modal.title')}
        icon={<Icon type="Sliders" className="accounting-items-filters-modal__icon" />}
        size="wide"
      >
        <div className="accounting-items-filters-modal__filters"></div>
        <div className="accounting-items-filters-modal__select">
          <Select
            data-testid="employer-select"
            placeholder={t('common:select')}
            label={t('economy:accounting-items-filters-modal.employer-label')}
            options={employerOptions}
            selected={employerOptions.find((option) => selectedEmployerId == option.value)}
            onChange={handleEmployerSelectChange}
            small
            outlined
          />
        </div>
        <QueryWrapper
          query={GET_INSURANCE_PROVIDERS}
          type="list"
          options={{ variables: { selectedEmployerId }, skip: !selectedEmployerId }}
        >
          {(insuranceProviders: InsuranceProvider[]) => {
            const insuranceProviderOptions = unique(createOptionsFromList(insuranceProviders, 'id', 'legalName'));
            return (
              <div className="accounting-items-filters-modal__filters">
                <div className="accounting-items-filters-modal__select">
                  <Select
                    placeholder={t('common:select')}
                    label={t('economy:accounting-items-filters-modal.insurance-provider-label')}
                    selectAll
                    options={insuranceProviderOptions}
                    selected={insuranceProviderOptions.filter((option) =>
                      selectedInsuranceProviderIds.includes(option.value),
                    )}
                    onChange={handleInsuranceProvidersFilterChange}
                    small
                    outlined
                    multiple
                  />
                </div>
              </div>
            );
          }}
        </QueryWrapper>
        <div className="accounting-items-filters-modal__date-section">
          <div className="date-filter">
            <div className="date-filter__period">
              <Typography component="label" variant="caption" className="date-filter__label">
                {t('common:period-filter.choose-period-start')}
              </Typography>
              <div className="date-filter__period__selects">
                <Select
                  options={yearsOptions}
                  alphabetical={false}
                  onChange={(e: Option<number>) => setFromPeriodYear(e.value)}
                  selected={yearsOptions.find((option) => option.value === fromPeriodYear)}
                  data-testid="start-year-select"
                  small
                  outlined
                />
                <Select
                  options={monthOptions}
                  alphabetical={false}
                  onChange={(e: Option<Month>) => setFromPeriodMonth(convertMonthEnumToStringWithZeroes(e.value))}
                  selected={monthOptions.find(
                    (option) =>
                      option.value ===
                      (fromPeriodMonth === null ? null : convertMonthNumberStringToEnum(fromPeriodMonth)),
                  )}
                  disabled={fromPeriodYear === null}
                  data-testid="start-month-select"
                  small
                  outlined
                />
              </div>
            </div>
            <div className="date-filter__period">
              <Typography component="label" variant="caption" className="date-filter__label">
                {t('common:period-filter.choose-period-end')}
              </Typography>
              <div className="date-filter__period__selects">
                <Select
                  options={yearsOptions}
                  alphabetical={false}
                  onChange={(e: Option<number>) => setToPeriodYear(e.value)}
                  selected={yearsOptions.find((option) => option.value == toPeriodYear)}
                  data-testid="end-year-select"
                  small
                  outlined
                />
                <Select
                  options={monthOptions}
                  alphabetical={false}
                  onChange={(e: Option<Month>) => setToPeriodMonth(convertMonthEnumToStringWithZeroes(e.value))}
                  selected={monthOptions.find(
                    (option) =>
                      option.value === (toPeriodMonth === null ? null : convertMonthNumberStringToEnum(toPeriodMonth)),
                  )}
                  disabled={toPeriodYear === null}
                  data-testid="end-month-select"
                  small
                  outlined
                />
              </div>
            </div>
          </div>
          <InfoMessage message={t('common:period-filter-info-message')} />
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button
            label={t('economy:accounting-items-filters-modal.use-button')}
            disabled={useButtonDisabled}
            onClick={onApply}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AccountingItemsFiltersModal;
