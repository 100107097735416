import { ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListProperty from 'src/components/Common/ListProperty';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { getCorrectEventValue } from 'src/util/InsuranceEventUtil';
import { formatSweAmountTextWithSuffixWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';
import { usePeriodText } from 'src/util/date/PeriodUtil';

interface EmployeeInsuranceEventAboutTabProps {
  insuranceEvent: InsuranceEvent;
}

function EmployeeInsuranceEventAboutTab({ insuranceEvent }: EmployeeInsuranceEventAboutTabProps) {
  const { t } = useTranslation();

  return (
    <div className="employee-insurance-event-about-tab">
      <ListGroup variant="inline-edit">
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.insurance-provider-label')}
          value={stringOrBlank(insuranceEvent.insuranceProvider?.legalName)}
          data-testid="insurance-event-details-drawer__insurance-provider"
        />
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.reporting-type-label')}
          value={t(`reportingTypes:${insuranceEvent.reportingType}`)}
          data-testid="insurance-event-details-drawer__reporting-type"
        />
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.insurance-event-type-label')}
          value={t(`insuranceEventTypes:${insuranceEvent.insuranceEventType}`)}
          data-testid="insurance-event-details-drawer__insurance-event-type"
        />
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.benefit-label')}
          value={stringOrBlank(insuranceEvent.policyBenefitName?.name)}
          data-testid="insurance-event-details-drawer__benefit"
        />
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.benefit-type-label')}
          value={<Pill label={t(`benefitTypes:${insuranceEvent.benefitType}`)} />}
          data-testid="insurance-event-details-drawer__benefit-type"
        />
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.agreed-product-label')}
          value={stringOrBlank(insuranceEvent.employeeInsurance?.insurance?.agreedProduct?.name)}
          data-testid="insurance-event-details-drawer__agreed-product"
        />
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.wage-file-period-label')}
          value={stringOrBlank(usePeriodText(insuranceEvent.wageFilePeriod))}
          data-testid="insurance-event-details-drawer__wage-file-period"
        />
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.event-from-date-label')}
          value={stringOrBlank(insuranceEvent.eventFromDate)}
          data-testid="insurance-event-details-drawer__event-from-date"
        />
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.certify-insurance-product-condition-label')}
          value={t(
            `employeeInsuranceEvents:certify-insurance-product-condition.${
              !insuranceEvent.certifyInsuranceProductCondition ? 'false' : 'true'
            }`,
          )}
          data-testid="insurance-event-details-drawer__certify-insurance-product-condition"
        />
        {insuranceEvent.eventValues.map((eventValue) => (
          <ListProperty
            key={`EmployeeInsuranceEventAboutTab-${eventValue.id}`}
            label={t('employeeInsuranceEvents:drawer.about-tab.value-label')}
            value={
              <div className="insurance-event-about-tab__values">
                <Pill type="neutral" size="small" label={eventValue.eventValueType} />
                <Typography variant="caption">
                  {formatSweAmountTextWithSuffixWithTwoDecimals(getCorrectEventValue(eventValue))}
                </Typography>
              </div>
            }
          />
        ))}
        <ListProperty
          label={t('employeeInsuranceEvents:drawer.about-tab.status-label')}
          value={t(`insuranceEventStatus:${insuranceEvent.status}`)}
          data-testid="insurance-event-details-drawer__status"
        />
      </ListGroup>
    </div>
  );
}

export default EmployeeInsuranceEventAboutTab;
